<template>
	<v-list-item :value="item">
		<v-list-item-icon>
			<v-btn icon small> <v-icon color="grey" @click="showFiles">{{ expanded ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon></v-btn>

		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-title>{{item.name}}</v-list-item-title>
			<div v-if="expanded">
				<div v-for="(item, i) in items"	:key="i">
					<folder-list-item v-if="item.type == 'folder'" :item="item" :home="path" :allowUpload="allowUpload"></folder-list-item>
					<file-list-item v-if="item.type == 'file'" :item="item" :home="path" :allowUpload="allowUpload"></file-list-item>
				</div>
			</div>

		</v-list-item-content>
	</v-list-item>
</template>
<script>
	import {listFiles} from "../../assets/storageFunctions.js";
	export default{
		name: 'FolderListItem',
		props: {
			item: {type: Object },
			allowUpload: { type: Boolean },
		},
		data: () => {
			return {
				expanded: false,
				items: [],
			}
		},
		computed: {
			path(){
				return this.item.fullPath
			},
		},
		components: {
			FolderListItem: () => import('@c/storage/FolderListItem'),
			FileListItem: () => import('@c/storage/FileListItem'),
		},
		methods: {
			showFiles(){
				const self = this;
				self.expanded = !self.expanded;
				if( !self.items.length ){
					listFiles(self.path).then( a =>  {
						a.prefixes.forEach( p => {
							p.type = "folder";
							self.items.push(p)
						} );
						a.items.forEach( i => {
							i.type = "file";
							self.items.push( i )
						})
					})
				}
			}
		}
	}
</script>